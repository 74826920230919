$(document).ready(function () {
    var $form = $('#newsletter-form');
    $form.on('beforeSubmit', function () {
        var data = $form.serialize();
        data['save'] = true;
        $.ajax({
            url: $form.attr('action'),
            type: 'POST',
            data: data,
            success: function (data) {
                swal({
                    title: 'Mindjárt kész!',
                    text: 'Egy lépés van hátra: küldtünk egy linket a megadott címre, kattintson rá a feliratkozás megerősítéséhez.',
                    type: "success",
                });
                // Implement successful
            },
            error: function (jqXHR, errMsg) {
                alert(errMsg);
            }
        });
        return false; // prevent default submit
    });



});
