$(document).ready(function() {
    iframe = $('iframe');
    iframe.each(function (index, element) {
        var title = element.attributes.title;
        if (!title) {
            $(this).wrap('<div class="iframe-wrapper"></div>');
        }
        if($(this).hasClass('instagram-media')) {
            instaFix($(this));
        }
    });


});

function instaFix(iframe) {

    var vwidth = $(window).width();
    var fixWidth = vwidth - 78;
    if(vwidth < 411) {
        iframe.css('min-width', fixWidth);
    }


    $(window).on('resize', function () {
        vwidth = $(window).width();
        if(vwidth < 411) {
            fixWidth = vwidth - 78;
            iframe.css('min-width', fixWidth);
        }
    });

}